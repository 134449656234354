exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-en-about-js": () => import("./../../../src/pages/en/about.js" /* webpackChunkName: "component---src-pages-en-about-js" */),
  "component---src-pages-en-contact-js": () => import("./../../../src/pages/en/contact.js" /* webpackChunkName: "component---src-pages-en-contact-js" */),
  "component---src-pages-en-index-js": () => import("./../../../src/pages/en/index.js" /* webpackChunkName: "component---src-pages-en-index-js" */),
  "component---src-pages-en-privacy-policy-js": () => import("./../../../src/pages/en/privacy-policy.js" /* webpackChunkName: "component---src-pages-en-privacy-policy-js" */),
  "component---src-pages-en-products-acid-peel-js": () => import("./../../../src/pages/en/products/acid-peel.js" /* webpackChunkName: "component---src-pages-en-products-acid-peel-js" */),
  "component---src-pages-en-products-anti-aging-peeling-js": () => import("./../../../src/pages/en/products/anti-aging-peeling.js" /* webpackChunkName: "component---src-pages-en-products-anti-aging-peeling-js" */),
  "component---src-pages-en-products-anti-aging-serum-js": () => import("./../../../src/pages/en/products/anti-aging-serum.js" /* webpackChunkName: "component---src-pages-en-products-anti-aging-serum-js" */),
  "component---src-pages-en-products-energizing-serum-js": () => import("./../../../src/pages/en/products/energizing-serum.js" /* webpackChunkName: "component---src-pages-en-products-energizing-serum-js" */),
  "component---src-pages-en-products-energyze-js": () => import("./../../../src/pages/en/products/energyze.js" /* webpackChunkName: "component---src-pages-en-products-energyze-js" */),
  "component---src-pages-en-products-js": () => import("./../../../src/pages/en/products.js" /* webpackChunkName: "component---src-pages-en-products-js" */),
  "component---src-pages-en-products-lifting-serum-js": () => import("./../../../src/pages/en/products/lifting-serum.js" /* webpackChunkName: "component---src-pages-en-products-lifting-serum-js" */),
  "component---src-pages-en-products-normalizing-serum-js": () => import("./../../../src/pages/en/products/normalizing-serum.js" /* webpackChunkName: "component---src-pages-en-products-normalizing-serum-js" */),
  "component---src-pages-en-products-oxygenating-sorbet-js": () => import("./../../../src/pages/en/products/oxygenating-sorbet.js" /* webpackChunkName: "component---src-pages-en-products-oxygenating-sorbet-js" */),
  "component---src-pages-en-products-regenerating-serum-js": () => import("./../../../src/pages/en/products/regenerating-serum.js" /* webpackChunkName: "component---src-pages-en-products-regenerating-serum-js" */),
  "component---src-pages-en-products-relift-js": () => import("./../../../src/pages/en/products/relift.js" /* webpackChunkName: "component---src-pages-en-products-relift-js" */),
  "component---src-pages-en-products-renewing-peeling-js": () => import("./../../../src/pages/en/products/renewing-peeling.js" /* webpackChunkName: "component---src-pages-en-products-renewing-peeling-js" */),
  "component---src-pages-en-products-revitalizing-serum-js": () => import("./../../../src/pages/en/products/revitalizing-serum.js" /* webpackChunkName: "component---src-pages-en-products-revitalizing-serum-js" */),
  "component---src-pages-en-products-smoothing-serum-js": () => import("./../../../src/pages/en/products/smoothing-serum.js" /* webpackChunkName: "component---src-pages-en-products-smoothing-serum-js" */),
  "component---src-pages-en-products-ultra-repair-serum-js": () => import("./../../../src/pages/en/products/ultra-repair-serum.js" /* webpackChunkName: "component---src-pages-en-products-ultra-repair-serum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-marce-js": () => import("./../../../src/pages/o-marce.js" /* webpackChunkName: "component---src-pages-o-marce-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */),
  "component---src-pages-produkty-acid-peel-js": () => import("./../../../src/pages/produkty/acid-peel.js" /* webpackChunkName: "component---src-pages-produkty-acid-peel-js" */),
  "component---src-pages-produkty-dotleniajacy-sorbet-js": () => import("./../../../src/pages/produkty/dotleniajacy-sorbet.js" /* webpackChunkName: "component---src-pages-produkty-dotleniajacy-sorbet-js" */),
  "component---src-pages-produkty-energetyzujace-serum-js": () => import("./../../../src/pages/produkty/energetyzujace-serum.js" /* webpackChunkName: "component---src-pages-produkty-energetyzujace-serum-js" */),
  "component---src-pages-produkty-energyze-js": () => import("./../../../src/pages/produkty/energyze.js" /* webpackChunkName: "component---src-pages-produkty-energyze-js" */),
  "component---src-pages-produkty-js": () => import("./../../../src/pages/produkty.js" /* webpackChunkName: "component---src-pages-produkty-js" */),
  "component---src-pages-produkty-liftingujace-serum-js": () => import("./../../../src/pages/produkty/liftingujace-serum.js" /* webpackChunkName: "component---src-pages-produkty-liftingujace-serum-js" */),
  "component---src-pages-produkty-normalizujace-serum-js": () => import("./../../../src/pages/produkty/normalizujace-serum.js" /* webpackChunkName: "component---src-pages-produkty-normalizujace-serum-js" */),
  "component---src-pages-produkty-odmladzajace-serum-js": () => import("./../../../src/pages/produkty/odmladzajace-serum.js" /* webpackChunkName: "component---src-pages-produkty-odmladzajace-serum-js" */),
  "component---src-pages-produkty-odnawiajacy-peeling-js": () => import("./../../../src/pages/produkty/odnawiajacy-peeling.js" /* webpackChunkName: "component---src-pages-produkty-odnawiajacy-peeling-js" */),
  "component---src-pages-produkty-przeciwstarzeniowy-peeling-js": () => import("./../../../src/pages/produkty/przeciwstarzeniowy-peeling.js" /* webpackChunkName: "component---src-pages-produkty-przeciwstarzeniowy-peeling-js" */),
  "component---src-pages-produkty-regeneracyjne-serum-js": () => import("./../../../src/pages/produkty/regeneracyjne-serum.js" /* webpackChunkName: "component---src-pages-produkty-regeneracyjne-serum-js" */),
  "component---src-pages-produkty-relift-js": () => import("./../../../src/pages/produkty/relift.js" /* webpackChunkName: "component---src-pages-produkty-relift-js" */),
  "component---src-pages-produkty-rewitalizujace-serum-js": () => import("./../../../src/pages/produkty/rewitalizujace-serum.js" /* webpackChunkName: "component---src-pages-produkty-rewitalizujace-serum-js" */),
  "component---src-pages-produkty-ultranaprawcze-serum-js": () => import("./../../../src/pages/produkty/ultranaprawcze-serum.js" /* webpackChunkName: "component---src-pages-produkty-ultranaprawcze-serum-js" */),
  "component---src-pages-produkty-wygladzajace-serum-js": () => import("./../../../src/pages/produkty/wygladzajace-serum.js" /* webpackChunkName: "component---src-pages-produkty-wygladzajace-serum-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

