import * as React from "react"
import { Link } from "gatsby"

import Layout from "../../components/layout"
import ContentPage from "../../layouts/contentPage"
import CategoryBox from "/src/components/categoryBox.js"
import ProducstList from "/src/components/productsList.js"
import Seo from "/src/components/seo.js"



const Products = () => (
    <Layout>
      <Seo title="Products" />
      <ContentPage>
          <ProducstList>
            <CategoryBox lang={"EN"} className="energyze" name="Energyze" color="lime" bgtext="WIT.C" url="/en/products/energyze"/>
            <CategoryBox lang={"EN"} className="relift" name="Relift" color="magenta" bgtext="WIT.A" url="/en/products/relift"/>
            <CategoryBox lang={"EN"} className="acid" name="Acid Peel" color="blue" bgtext="AHA" bgtext2="PHA" url="/en/products/acid-peel"/>
          </ProducstList>
      </ContentPage>
    </Layout>
)

export default Products
